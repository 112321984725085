import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout/Layout'
import Content, { HTMLContent } from '../components/layout/Content'
import PreviewCompatibleImage from '../components/image/PreviewCompatibleImage'


export const AboutPageTemplate = ({
                                    title, content, contentComponent, main }) => {
  const PageContent = contentComponent || Content

  return (
      <section className="section section--gradient has-text-justified">
        <div className="container">
          <div className="columns"  style={{ textAlign: 'justify' }}>
            <div className="column is-12">
              <div className="columns" >
                <div className="column">

                  <h3 className="title is-size-4">Qui somme nous ?</h3>
                  <p>L’atelier Forma c’est d’abord la rencontre de trois jeunes femmes sur leur lieu de travail, dans une boîte suédoise un peu connue.</p>
                  <br/>
                  <p>Coline architecte d’intérieur, mais aussi créatrice de “la vie des choses” sur instagram, passionnée par les objets chinés et l’artisanat.</p>
                  <br/>
                  <p>Emma visual merchandiser, également cuisinière qui aime autant concevoir des vitrines qu’être plongée dans ses livres de cuisine pour élaborer ses prochaines recettes.</p>
                  <br/>
                  <p>Et Lisa graphiste mais surtout céramiste, sous le nom de Li-céramique, qui n'attendait qu'un atelier pour développer sa passion.</p>
                  <br/>
                  <p>L’atelier Forma c’est ensuite une prise de conscience et une bonne dose d’ambition. Animées par les mêmes besoins de créer et de redonner du sens à nos métiers, nous avons décidé de nous lancer dans la belle aventure de l’entreprenariat en imaginant un lieu qui nous ressemble!</p>
                  <br/>


                </div>
                <div className="column has-text-centered" style={{margin:'auto'}}>
                  <div class="row">
                    <PreviewCompatibleImage imageInfo={main.image0} style={{marginBottom:'4rem'}}/>
                  </div>

                  <div class="row">
                    {/*<PreviewCompatibleImage imageInfo={main.image1} />*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PageContent className="content" content={content} />
        </div>
      </section>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  main: PropTypes.shape({
    image0: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
      <Layout>
        <AboutPageTemplate
            contentComponent={HTMLContent}
            title={post.frontmatter.title}
            content={post.html}
            main={post.frontmatter.main}
        />
      </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        main {
          image0 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
              }
            }
            maxWidth
          }
          image1 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
              }
            }
            maxWidth
          }
        }
      }
    }
  }
`
